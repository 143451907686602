'use client';

import { useEffect, useState } from 'react';

import { PackageComponentWrapper } from '../../PackageComponentWrapper';
import { renderExpandButton } from '../BrandDescription/default';
import { SpecificationsProps } from './index';
import {
  ProductSpecificationItemProps,
  ProductSpecificationProps,
  calculateBarWidth
} from '../default';

export const SpecificationsAccordion = ({
  variants,
  specifications,
  coaUrl,
  onCoaClick
}: SpecificationsProps): JSX.Element | null => {
  const [open, setOpen] = useState<boolean>(true);

  useEffect(() => {
    if (
      window &&
      typeof window !== 'undefined' &&
      window.location.hash.includes('#specifications') &&
      !open
    ) {
      setOpen(true);
    }
  }, [window?.location.hash]);

  const calculateSecondBarWidth = (
    item: ProductSpecificationItemProps['items'][number],
    section: ProductSpecificationItemProps
  ) => {
    if (!item.value || typeof item.value !== 'string' || !section) return 0;
    if (item.value.includes('-') && typeof item.value == 'string') {
      const rangeValues = item.value.split('-');
      if (item.value.includes('%')) {
        return (
          +(rangeValues[1]?.split('%')[0] as string) -
          +(rangeValues[0]?.split('%')[0] as string)
        );
      } else if (item.value.includes('mg')) {
        const maxRangeItem = {
          key: item.key,
          value: `${
            +(rangeValues[1]?.split('mg')[0] as string) -
            +(rangeValues[0]?.split('mg')[0] as string)
          }mg`
        };
        return calculateBarWidth(maxRangeItem, section);
      } else {
        return 0;
      }
    }
    return 0;
  };

  return (
    <PackageComponentWrapper minWidth={false}>
      <div className="specifications-accordion" id="specifications">
        <div className="specifications-heading d-flex align-items-center justify-content-between">
          <h2 className="title-l text-black margin-bottom-0">
            Product Details
          </h2>
          {renderExpandButton(open, setOpen)}
        </div>
        <div className={open ? 'content active margin-bottom-16' : 'content'}>
          <div className="product-details d-flex flex-column">
            {coaUrl ? (
              <div className="d-flex justify-content-between align-items-center padding-bottom-16 item margin-bottom-0">
                <p
                  style={{
                    width: '50%'
                  }}
                  className="d-flex align-items-center body-sm font-bold text-black uppercase item-name-container margin-bottom-0">
                  <span
                    className="margin-right-25 item-name"
                    style={{
                      width: '100%'
                    }}>
                    Certificate of Analysis
                  </span>
                </p>
                <p
                  style={{ width: '50%' }}
                  className="body-sm text-right text-black margin-bottom-0">
                  <a
                    onClick={onCoaClick}
                    className="body-sm text-primary underline"
                    href={coaUrl}
                    target="_blank">
                    View Full Report
                  </a>
                </p>
              </div>
            ) : null}
            {specifications &&
              Object.keys(specifications).map((p) => {
                const current: ProductSpecificationItemProps =
                  specifications[p as keyof ProductSpecificationProps];
                if (current.items.length) {
                  return (
                    <div
                      key={p}
                      className="description-section d-flex flex-column padding-top-16">
                      <h4 className="section-header body-xl text-black padding-bottom-16 margin-bottom-0">
                        {current.title}
                      </h4>
                      {current.items.map((item) => {
                        if (item.value !== 'Not Applicable') {
                          const barWidth = current.showBar
                            ? calculateBarWidth(item, current, variants)
                            : 0;
                          return (
                            <div
                              key={item.key}
                              className="d-flex justify-content-between align-items-center padding-bottom-16 item margin-bottom-0">
                              <p
                                style={{
                                  width:
                                    current.showBar && barWidth > 1
                                      ? '86%'
                                      : '50%'
                                }}
                                className="d-flex align-items-center body-sm font-bold text-black uppercase item-name-container margin-bottom-0">
                                <span
                                  className="padding-right-25 item-name"
                                  style={{
                                    width:
                                      current.showBar && barWidth > 1
                                        ? '50%'
                                        : '100%'
                                  }}>
                                  {item.key}
                                </span>
                                {/* show if greater than 1 or else it looks empty */}
                                {current.showBar && barWidth > 1 ? (
                                  <span
                                    className="d-flex"
                                    style={{
                                      width: '50%',
                                      backgroundColor: '#eaeaea'
                                    }}>
                                    <span
                                      className="item-bar"
                                      style={{
                                        backgroundColor:
                                          current.title === 'Strength Profile'
                                            ? '#061e21'
                                            : '#99f0d4',
                                        width: `${barWidth}%`
                                      }}
                                    />
                                    {item.hasRange ? (
                                      <span
                                        className="secondary-item-bar"
                                        style={{
                                          width: `${calculateSecondBarWidth(
                                            item,
                                            current
                                          )}%`
                                        }}
                                      />
                                    ) : null}
                                  </span>
                                ) : null}
                              </p>
                              <p
                                style={
                                  current.showBar && barWidth > 1
                                    ? { width: '14%' }
                                    : { width: '50%' }
                                }
                                className="body-sm text-right margin-bottom-0">
                                {item.value}
                              </p>
                            </div>
                          );
                        }
                      })}
                    </div>
                  );
                }
              })}
          </div>
        </div>
      </div>
    </PackageComponentWrapper>
  );
};
