import { CarouselType, StorefrontCarouselBanner } from '../index';
import { CategoryTile, CategoryTileProps } from '../../CategoryTile';
import { ProductTile, ProductTileProps } from '../../ProductTile';
import { SpecialTile, SpecialTileProps } from '../../SpecialTile';
import { DatalayerAnalytics } from 'services';
import { SiteWideWrapperProps } from '../../../hooks/siteWideContext';
import { BrandTile, BrandTileProps } from './BrandTile';

export const CarouselTile = (
  type: CarouselType,
  item: StorefrontCarouselBanner,
  isLoading = false,
  isMobile: boolean,
  index: number,
  selectedDispensary?: SiteWideWrapperProps['selectedDispensary']
) => {
  switch (type) {
    case 'brands': {
      return (
        <BrandTile
          props={{
            ...(item as Omit<BrandTileProps, 'isLoading'>),
            isLoading
          }}
        />
      );
    }
    case 'categories': {
      return (
        <CategoryTile
          props={
            isLoading
              ? {
                  isLoading: true
                }
              : {
                  isLoading: false,
                  link: (item as CategoryTileProps).link,
                  title: (item as CategoryTileProps).title
                }
          }
        />
      );
    }
    case 'products': {
      if (isLoading) {
        return (
          <ProductTile
            props={{
              isLoading: true
            }}
          />
        );
      } else if (selectedDispensary) {
        return (
          <ProductTile
            props={{
              ...(item as Omit<
                ProductTileProps,
                | 'analyticsContext'
                | 'handleAnalytics'
                | 'isLoading'
                | 'isMobile'
                | 'shopLink'
              >),
              handleAnalytics: () => {
                DatalayerAnalytics.pushCarouselClick({
                  page_type: 'shop/home',
                  dispensary: selectedDispensary,
                  carousel_name: item.containingCarouselName || '',
                  product: (item as ProductTileProps).product,
                  index
                });
              },
              isLoading,
              isMobile,
              shopLink: selectedDispensary.shopLink
            }}
          />
        );
      }
      // remove once selected dispensary is gone in redesign
      return (
        <ProductTile
          props={{
            isLoading: true
          }}
        />
      );
    }
    case 'specials-drawer':
    case 'specials': {
      return (
        <SpecialTile
          props={{
            ...(item as SpecialTileProps),
            isLoading,
            index
          }}
        />
      );
    }
    default: {
      return null;
    }
  }
};
