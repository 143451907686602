'use client';

import { StorefrontCarousel as Default } from './default';
import { Loader } from './loader';
import { CategoryTileProps } from '../CategoryTile';
import { ProductTileProps } from '../ProductTile';
import { SpecialTileProps } from '../SpecialTile';
import { BrandTileProps } from './components/BrandTile';

import './storefront-carousel.scss';

export type StorefrontCarouselProps = {
  banners: StorefrontCarouselBanner[];
  helpText?: string;
  isLoading: boolean;
  isMobile: boolean;
  link?: string;
  title?: string;
  type: CarouselType;
};

export type StorefrontCarouselBanner = (
  | Omit<BrandTileProps, 'isLoading'>
  | Pick<CategoryTileProps, 'title'>
  | Omit<
      ProductTileProps,
      | 'analyticsContext'
      | 'isLoading'
      | 'handleAnalytics'
      | 'shopLink'
      | 'isMobile'
    >
  | Omit<SpecialTileProps, 'isLoading' | 'currentPathDispensary'>
) & {
  containingCarouselName?: string;
};

export type CarouselDirection = 'next' | 'previous';

export type CarouselType =
  | 'brands'
  | 'categories'
  | 'products'
  | 'specials'
  | 'specials-drawer';

export const StorefrontCarousel = (
  props: StorefrontCarouselProps
): JSX.Element | null => {
  if (!props.banners?.length && !props.isLoading) return null;
  if (props.isLoading) {
    return <Loader title={props.title} type={props.type} />;
  }
  return <Default {...props} banners={props.banners} />;
};
