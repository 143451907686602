import { Consolidate_Product } from 'services';
import { getStrain } from '../../../utils/ecommerce';

import Tag from '../../../assets/svgs/product/strain-tag.svg';

import './strain-tag.scss';

export type StrainTagProps = {
  strain: Consolidate_Product['strainType'];
};

// Note: make sure to make the parent relative
export const StrainTag = ({ strain }: StrainTagProps): JSX.Element | null => {
  const strainParsed = getStrain(strain);
  if (strainParsed) {
    return (
      <>
        <div
          className={`strain-tag ${strainParsed.toLowerCase()}`}
          data-test="strain">
          <Tag />
        </div>
        <span className="strain-name caption font-bold text-white">
          {strainParsed}
        </span>
      </>
    );
  }
  return null;
};
