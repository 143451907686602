'use client';

import { usePathname } from 'next/navigation';
import { useEffect, useState } from 'react';
import { Params } from 'next/dist/shared/lib/router/utils/route-matcher';
import Cookies from 'js-cookie';

import {
  Consolidate_Order,
  DatalayerAnalytics,
  optionsMap,
  optionsUnMap
} from 'services';
import { getOrderConfirmation } from '@cura/next-server/OrderConfirmationServerAction';

import { BreadCrumbs } from '../components/Breadcrumbs';
import { PageState } from '../components/PageState';
import { OrderConfirmation } from '../components/OrderConfirmation';
import { useSiteWideContext } from '../hooks/siteWideContext';
import { useDispensaryPathContext } from '../hooks/dispensaryPathContextProvider';

export type OrderConfirmationProps = {
  searchParams: Params;
};

export const OrderConfirmationPage = ({
  searchParams
}: OrderConfirmationProps) => {
  const { currentPathDispensary, offerList } = useDispensaryPathContext();
  const { clearCart, user } = useSiteWideContext();
  const pathname = usePathname();
  const orderNumber = searchParams.orderNumber || null;

  const [orderLoading, setOrderLoading] = useState(true);
  const [pageViewFired, setPageViewFired] = useState(false);
  const [order, setOrder] = useState<Consolidate_Order | null>();
  const accountId = Cookies.get('curaleafAccountId');

  useEffect(() => {
    if (
      (!accountId && orderNumber && currentPathDispensary) ||
      (accountId && user && orderNumber && currentPathDispensary)
    ) {
      getOrderConfirmation(
        currentPathDispensary,
        orderNumber,
        offerList,
        user?._id ? user : undefined
      )
        .then((res) => {
          setOrder(res);
          setOrderLoading(false);
        })
        .finally(() => clearCart && clearCart());
    }
  }, [orderNumber, currentPathDispensary, user]);

  useEffect(() => {
    if (currentPathDispensary && !pageViewFired) {
      let pageType = 'shop/order-confirmation';
      if (pathname.includes('/android')) pageType += '/android';

      DatalayerAnalytics.pushPageView({
        page_type: pageType,
        consolidateDispensary: currentPathDispensary
      });
      setPageViewFired(true);
    }
  }, [pathname, currentPathDispensary]);

  useEffect(() => {
    if (order) {
      if (!order.viewed && currentPathDispensary) {
        const items = order.items.map((item) => {
          const variant = item.product.variants.find(
            (v) => optionsMap(v.option) === optionsMap(item.option)
          )!; // Product should always have a variant that matches!
          return {
            item_id: variant.id,
            item_name: item.product.name,
            discount: DatalayerAnalytics.calculateDiscount(variant),
            item_brand: item.product.brand?.name,
            item_category: item.product.category,
            item_category2: item.product.subcategory || undefined,
            item_variant: optionsUnMap(variant.option),
            affiliation: currentPathDispensary.friendlyName,
            price: variant.isSpecial
              ? variant.specialPrice || undefined
              : variant.price || undefined,
            quantity: item.quantity
          };
        });
        DatalayerAnalytics.pushPurchase({
          value: order.total,
          transaction_id: order.orderNumber,
          tax: order.tax,
          subtotal: order.subtotal,
          order_discount: order.discounts,
          fee: order.fees,
          items,
          consolidateDispensary: currentPathDispensary
        });
      }
    }
  }, [order]);

  if (!orderLoading && !order) {
    DatalayerAnalytics.pushErrorEvent({
      category: 'expected',
      location: 'orderConfirmationPage',
      description: 'Order Not Found',
      consolidateDispensary: currentPathDispensary
    });
    return (
      <PageState
        header="Order not found."
        link={{
          url: currentPathDispensary.shopLink,
          text: 'Start shopping!'
        }}
      />
    );
  }

  return (
    <>
      <BreadCrumbs
        props={{
          breadcrumb: [
            {
              id: 0,
              urlFragment: currentPathDispensary.shopLink,
              breadcrumbName: 'Home'
            }
          ],
          currentPage: 'Order Confirmation',
          isLoading: false
        }}
      />
      {orderLoading ? (
        <OrderConfirmation isLoading={true} />
      ) : !orderLoading && order ? (
        <OrderConfirmation
          isLoading={false}
          order={order}
          orderNumber={orderNumber}
        />
      ) : null}
    </>
  );
};
