import { PriceSectionProps } from '.';

export const PriceSection = ({
  isMobile,
  type,
  variant
}: PriceSectionProps) => {
  const fixedPrice = (price: number) => {
    if (JSON.stringify(price).includes('.')) {
      return price.toFixed(2);
    }
    return price;
  };

  let additionalClass = '';
  if (type === 'pdp') {
    additionalClass += 'h1';
  } else if (type === 'tile') {
    additionalClass += isMobile ? 'title-l' : 'h3';
  } else if (type === 'list-tile') {
    additionalClass += 'title-l';
  }

  if (variant.price) {
    if (variant.isSpecial && variant.specialPrice) {
      if (type === 'tile' || type === 'list-tile') {
        return (
          <div className="d-flex align-items-center">
            <span className={`new-price text-black ${additionalClass}`}>
              ${fixedPrice(variant.specialPrice)}
            </span>
            <span
              className={
                'body-l font-bold old-price strikethrough margin-left-10 text-error-dark'
              }>
              ${fixedPrice(variant.price)}
            </span>
          </div>
        );
      } else if (type === 'pdp') {
        return (
          <span className="price">
            <span className={`new-price body-sm text-error-dark`}>
              ${fixedPrice(variant.specialPrice)}
            </span>
          </span>
        );
      }
    }
    if (type === 'pdp') {
      return (
        <span className="price">
          <span className={`current-price body-sm text-black`}>
            ${fixedPrice(variant.price)}
          </span>
        </span>
      );
    }
    return (
      <div>
        <span className={`current-price text-black ${additionalClass}`}>
          ${fixedPrice(variant.price)}
        </span>
      </div>
    );
  }
  return null;
};
