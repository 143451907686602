import { CarouselType, StorefrontCarouselBanner } from './index';
import { CarouselTile } from './components/tile';
import { LoadingSkeleton } from '../Loader/Skeleton';
import { PackageComponentWrapper } from '../PackageComponentWrapper';

type LoaderProps = {
  title?: string;
  type: CarouselType;
};

export const Loader = ({ title, type }: LoaderProps) => {
  const items = type === 'categories' ? 7 : 5;
  const emptyTileArray = new Array(items).fill(undefined);

  return (
    <PackageComponentWrapper additionalClass="container-lr">
      <div className="storefront-carousel d-flex flex-column">
        <div className="header d-flex justify-content-between align-items-end">
          <h2 className="title title-l text-primary margin-0">
            {title || <LoadingSkeleton width="200px" />}
          </h2>
        </div>
        <div className="slider d-flex">
          {emptyTileArray.map((item, id) => (
            <div key={id} data-type={type} className="item">
              {CarouselTile(
                type,
                item as unknown as StorefrontCarouselBanner,
                true,
                false,
                0
              )}
            </div>
          ))}
        </div>
      </div>
    </PackageComponentWrapper>
  );
};
