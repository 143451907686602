import { Checkbox } from 'primereact/checkbox';
import { dutchieFriendlyName } from 'services';
import { FilterAccordionProps } from '../index';
import { FilterKeys, FilterMenuItem } from '../../index';
import { useSiteWideContext } from '../../../../../hooks/siteWideContext';

import CloseIcon from '../../../../../assets/svgs/close/close-teal-circle.svg';

export const FilterCheckboxItem = ({
  item,
  onSelect,
  header
}: {
  item: FilterMenuItem;
  onSelect: FilterAccordionProps['onSelect'];
  header: FilterKeys;
}) => {
  const { width } = useSiteWideContext();
  if (width && width >= 1024) {
    return (
      <div className="filter-item d-flex flex-row align-items-center">
        <Checkbox
          tabIndex={0}
          data-test="checkbox"
          value={item.label}
          checked={item.isSelected}
          onChange={() => onSelect(header, item)}
          onKeyDown={(event) => {
            if (event.key === 'Enter') onSelect(header, item);
          }}
        />
        <label>
          {header !== 'Brands' && header !== 'Options'
            ? dutchieFriendlyName(item.label)
            : item.label}
        </label>
      </div>
    );
  } else {
    return (
      <a
        tabIndex={0}
        type="button"
        data-selected={item.isSelected}
        className="mobile-filter-tag d-flex pointer bg-white sm-border-radius body-m"
        onClick={() => onSelect(header, item)}
        onKeyDown={(event) => {
          if (event.key === 'Enter') onSelect(header, item);
        }}>
        {dutchieFriendlyName(item.label)}
        {item.isSelected ? <CloseIcon /> : null}
      </a>
    );
  }
};
