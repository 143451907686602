import { Sidebar } from 'primereact/sidebar';
import { useState } from 'react';
import classNames from 'classnames';

import { useSiteWideContext } from '../../../hooks/siteWideContext';
import { FilterAccordionWrapper } from '../ProductFilter/default';
import { ProductSortFilterProps } from '.';
import { FilterMenus } from '../ProductFilter';
import { Select } from '../../Select';

import GridIcon from '../../../assets/svgs/filter-sort/icon-view-grid.svg';
import ListIcon from '../../../assets/svgs/filter-sort/icon-view-list.svg';
import FilterIcon from '../../../assets/svgs/filter-sort/filter.svg';

export const SortBySelect = ({
  filters,
  onSelectFilter,
  sortBy
}: {
  filters: FilterMenus;
  onSelectFilter: ProductSortFilterProps['onSelectFilter'];
  sortBy: ProductSortFilterProps['sortBy'];
}) => {
  const menuItems = [
    {
      label: 'Price: High to Low',
      value: 'price-asc-rank'
    },
    {
      label: 'Price: Low to High',
      value: 'price-desc-rank'
    },
    {
      label: 'A-Z',
      value: 'alphabetized'
    }
  ];

  // Hides potency if they're all the same or none exists
  if (filters.Potency && filters.Potency.length) {
    menuItems.unshift(
      {
        label: 'THC: High to Low',
        value: 'potency-percent-asc-rank'
      },
      {
        label: 'THC: Low to High',
        value: 'potency-percent-desc-rank'
      }
    );
  }

  // These should come before Potency
  menuItems.unshift(
    {
      label: 'Recommended',
      value: 'recommended'
    },
    {
      label: 'Popular',
      value: 'popular'
    }
  );

  return (
    <Select
      id="sort-by"
      onSelectFilter={onSelectFilter}
      selected={sortBy}
      placeholder="Sort by"
      menuItems={menuItems}
    />
  );
};

export const ProductSortFilter = ({
  filters,
  onSelectFilter,
  pageTitle,
  pageType,
  productView,
  productLength,
  searchParams,
  setProductView,
  sortBy,
  shopLink
}: ProductSortFilterProps): JSX.Element | null => {
  const { width } = useSiteWideContext();
  const [open, setOpen] = useState<boolean>(false);

  const closeDrawer = () => setOpen(false);

  if (width && width <= 1024) {
    return (
      <>
        <h1 className="page-title h3 text-primary container-lr margin-0 text-center">
          {pageTitle}
        </h1>
        <div className="product-sort-filter container-lr">
          <div className="button-group d-flex">
            <a
              tabIndex={0}
              role="button"
              data-test="filter-button"
              className="d-flex justify-content-center align-items-center body-m sm-border-radius pointer"
              onClick={() => setOpen(!open)}
              onKeyDown={(event) => {
                if (event.key === 'Enter') setOpen(!open);
              }}>
              <FilterIcon />
              <span className="margin-left-10 text-primary body-m font-bold">
                Sort + Filter
              </span>
            </a>
          </div>
          <Sidebar
            data-test="sidebar"
            closeOnEscape
            showCloseIcon={false}
            visible={open}
            position="bottom"
            className="disable-scroll filter-sidebar"
            onHide={closeDrawer}>
            <FilterAccordionWrapper
              searchParams={searchParams}
              filters={filters}
              isLoading={false}
              onClose={closeDrawer}
              onSelect={onSelectFilter}
              pageType={pageType}
              productLength={productLength}
              shopLink={shopLink}
              sortBy={sortBy}
            />
          </Sidebar>
        </div>
      </>
    );
  } else {
    return (
      <div className="d-flex justify-content-between align-items-end">
        {pageTitle !== 'Specials' ? (
          <h1 className="page-title h3 text-primary container-lr margin-0">
            {pageTitle}
          </h1>
        ) : null}
        {pageType !== 'AllBrandsPage' && pageType !== 'AllSpecialsPage' ? (
          <div className="d-flex product-sort-filter-desktop">
            <div className="product-view d-flex align-items-end">
              <button
                className={classNames({
                  selected: productView === 'list'
                })}
                onClick={() => {
                  window.localStorage.setItem('productView', 'list');
                  setProductView('list');
                }}
                aria-label="product list view">
                <ListIcon />
              </button>
              <button
                className={classNames({
                  selected: productView === 'grid'
                })}
                onClick={() => {
                  window.localStorage.setItem('productView', 'grid');
                  setProductView('grid');
                }}
                aria-label="product grid view">
                <GridIcon />
              </button>
            </div>
            <div className="sort-by">
              <SortBySelect
                filters={filters}
                onSelectFilter={onSelectFilter}
                sortBy={sortBy}
              />
            </div>
          </div>
        ) : null}
      </div>
    );
  }
};
