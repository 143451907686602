'use client';

import Link from 'next/link';

import { ecommerceSanitizeQuery } from '../../utils/url';
import { ErrorBoundary } from '../../utils/nullErrorBoundary';
import { PackageComponentWrapper } from '../PackageComponentWrapper';
import { Markdown } from '../Markdown';
import { HomePageStorefrontCarousel } from '../StorefrontCarousel/homePage';

import './page-state.scss';
import { useEffect } from 'react';
import { DatalayerAnalytics } from 'services';

type Props = {
  header: string;
  link?: {
    url: string;
    text: string;
  };
  markdown?: string;
  showPopularProducts?: boolean;
};

export const PageState = ({
  header,
  link,
  markdown,
  showPopularProducts = false
}: Props): JSX.Element | null => {
  useEffect(() => {
    const url = typeof window !== 'undefined' ? window.location.href : '';

    if (url) {
      DatalayerAnalytics.pushErrorEvent({
        category: 'expected',
        location: 'productPage',
        description: header
      });
    }
  }, []);

  return (
    <>
      <PackageComponentWrapper additionalClass="container">
        <ErrorBoundary component="page-state">
          <div
            className="page-state bg-white d-flex flex-column justify-content-center align-items-center"
            data-test="page-state">
            <h1 className="text-primary margin-bottom-16 h3">{header}</h1>
            {markdown ? <Markdown content={markdown} /> : null}
            {link ? (
              <Link
                href={ecommerceSanitizeQuery(link.url)}
                className="underline body-xl">
                {link.text}
              </Link>
            ) : null}
          </div>
        </ErrorBoundary>
      </PackageComponentWrapper>
      {showPopularProducts ? (
        <PackageComponentWrapper>
          <HomePageStorefrontCarousel />
        </PackageComponentWrapper>
      ) : null}
    </>
  );
};
