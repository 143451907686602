'use client';

import { ArticleGrid } from '../ArticleGrid';
import { CTADivider } from '../CTADivider';
import { ErrorBoundary } from '../../utils/nullErrorBoundary';
import { FAQBlock } from '../FAQBlock';
import { HeroBannerCarousel } from '../HeroBannerCarousel';
import { ImageBlock } from '../ImageBlock';
import { InstagramGrid } from '../InstagramGrid';
import { MultiTextBlock } from '../MultiTextBlock';
import { PageBuilderMap } from '../../models/contentstackMap';
import { PromoCarousel } from '../PromoCarousel';
import { TextImageBlock } from '../TextImageBlock';
import { VideoBlock } from '../VideoBlock';
import { JobListing } from '../JobListing';
import { ProductCard } from '../ProductCard';
import { DecisionTree } from '../DecisionTree';
import { IFrame } from '../IFrame';
import { RedirectSearch } from '../RedirectSearch';
import { ContactUsForm } from '../FormBuilder/contact-us';
import { MarketingIntakeForm } from '../FormBuilder/marketing-intake';
import { slugify } from 'services';
import { InstructionsBlock } from '../InstructionsBlock';
import { StateStoreList } from '../StateStoreList';
import { HomePageStorefrontCarousel } from '../StorefrontCarousel/homePage';
import { useSiteWideContext } from '../../hooks/siteWideContext';
import { RewardsTable } from '../RewardsTable';

type PageBuilderProps = {
  component: PageBuilderMap;
  id: number;
};

export const PageBuilder = ({ component, id }: PageBuilderProps) => {
  const { isMobile } = useSiteWideContext();
  if (!component) {
    console.warn('Component Error: Component is null');
    return null;
  }
  try {
    switch (component.__typename) {
      case 'CuraleafPageModularBlocksArticleGrid': {
        if (component.articles.length > 0) {
          return (
            <ErrorBoundary
              component={component.__typename}
              key={`${component.__typename}-${id}`}>
              <ArticleGrid {...component} />
            </ErrorBoundary>
          );
        }
        return null;
      }
      case 'CuraleafPageModularBlocksCustomComponent': {
        if (component.type === 'careers') {
          return (
            <ErrorBoundary
              component={`${component.__typename}`}
              key={`${component.__typename}-${id}`}>
              <JobListing {...component} />
            </ErrorBoundary>
          );
        } else if (component.type === 'store-list') {
          return (
            <ErrorBoundary
              component={`${component.__typename}`}
              key={`${component.__typename}-${id}`}>
              <StateStoreList {...component} />
            </ErrorBoundary>
          );
        }
        return null;
      }
      case 'CuraleafPageModularBlocksCtaDivider': {
        return (
          <ErrorBoundary
            component={component.__typename}
            key={`${component.__typename}-${id}`}>
            <CTADivider {...component} />
          </ErrorBoundary>
        );
      }
      case 'CuraleafPageModularBlocksDecisionTree': {
        return (
          <ErrorBoundary
            component={component.__typename}
            key={`${component.__typename}-${id}`}>
            <DecisionTree {...component} />
          </ErrorBoundary>
        );
      }
      case 'CuraleafPageModularBlocksEmbeddableForm': {
        const type = component.type;

        switch (type) {
          case 'Contact Us': {
            return (
              <ErrorBoundary
                component={`${component.__typename}-${slugify(type)}`}
                key={`${component.__typename}-${id}`}>
                <ContactUsForm {...component} />
              </ErrorBoundary>
            );
          }
          case 'Opt In': {
            return (
              <ErrorBoundary
                component={`${component.__typename}-${slugify(type)}`}
                key={`${component.__typename}-${id}`}>
                <MarketingIntakeForm {...component} />
              </ErrorBoundary>
            );
          }
          default:
            console.warn(`Missing embeddable form component type: ${type}`);
            return null;
        }
      }
      case 'CuraleafPageModularBlocksFaqBlock': {
        if (component.faqs.length > 0) {
          return (
            <ErrorBoundary
              component={component.__typename}
              key={`${component.__typename}-${id}`}>
              <FAQBlock {...component} />
            </ErrorBoundary>
          );
        }
        return null;
      }
      case 'CuraleafPageModularBlocksHeroBannerCarousel': {
        return (
          <ErrorBoundary
            component={component.__typename}
            key={`${component.__typename}-${id}`}>
            <HeroBannerCarousel {...component} isMobile={isMobile} />
          </ErrorBoundary>
        );
      }
      case 'CuraleafPageModularBlocksIframe': {
        return (
          <ErrorBoundary
            component={component.__typename}
            key={`${component.__typename}-${id}`}>
            <IFrame {...component} />
          </ErrorBoundary>
        );
      }
      case 'CuraleafPageModularBlocksImageBlock': {
        return (
          <ErrorBoundary
            component={component.__typename}
            key={`${component.__typename}-${id}`}>
            <ImageBlock {...component} isMobile={isMobile} />
          </ErrorBoundary>
        );
      }
      case 'CuraleafPageModularBlocksInstagramGrid': {
        return (
          <ErrorBoundary
            component={component.__typename}
            key={`${component.__typename}-${id}`}>
            <InstagramGrid {...component} />
          </ErrorBoundary>
        );
      }
      case 'CuraleafPageModularBlocksInstructionsBlock': {
        return (
          <ErrorBoundary
            component={component.__typename}
            key={`${component.__typename}-${id}`}>
            <InstructionsBlock {...component} isMobile={isMobile} />
          </ErrorBoundary>
        );
      }
      case 'CuraleafPageModularBlocksMultiTextImageBlock': {
        return (
          <ErrorBoundary
            component={component.__typename}
            key={`${component.__typename}-${id}`}>
            <MultiTextBlock {...component} />
          </ErrorBoundary>
        );
      }
      case 'CuraleafPageModularBlocksProductCard': {
        return (
          <ErrorBoundary
            component={component.__typename}
            key={`${component.__typename}-${id}`}>
            <ProductCard {...component} />
          </ErrorBoundary>
        );
      }
      case 'CuraleafPageModularBlocksProductListing': {
        return (
          <ErrorBoundary
            component={component.__typename}
            key={`${component.__typename}-${id}`}>
            <div className="margin-top-16">
              <HomePageStorefrontCarousel />
            </div>
          </ErrorBoundary>
        );
      }
      case 'CuraleafPageModularBlocksPromoCarousel': {
        return (
          <ErrorBoundary
            component={component.__typename}
            key={`${component.__typename}-${id}`}>
            <PromoCarousel {...component} isMobile={isMobile} />
          </ErrorBoundary>
        );
      }
      case 'CuraleafPageModularBlocksRedirectSearch': {
        return (
          <ErrorBoundary
            component={component.__typename}
            key={`${component.__typename}-${id}`}>
            <RedirectSearch {...component} />
          </ErrorBoundary>
        );
      }
      case 'CuraleafPageModularBlocksRewardsTable': {
        return (
          <ErrorBoundary
            component={component.__typename}
            key={`${component.__typename}-${id}`}>
            <RewardsTable {...component} />
          </ErrorBoundary>
        );
      }
      case 'CuraleafPageModularBlocksTextImageBlock': {
        return (
          <ErrorBoundary
            component={component.__typename}
            key={`${component.__typename}-${id}`}>
            <TextImageBlock {...component} isMobile={isMobile} />
          </ErrorBoundary>
        );
      }
      case 'CuraleafPageModularBlocksVideoBlock': {
        return (
          <ErrorBoundary
            component={component.__typename}
            key={`${component.__typename}-${id}`}>
            <VideoBlock {...component} />
          </ErrorBoundary>
        );
      }
      default: {
        console.warn(`Component Error: Missing component: ${component}`);
        return null;
      }
    }
  } catch (err) {
    return null;
  }
};
