import { useState } from 'react';
import { Dropdown } from 'primereact/dropdown';

import {
  capitalize,
  DutchiePlus_OrderType,
  DutchiePlus_PricingType
} from 'services';
import { EcomProductGridProps, ProductViewType } from '../EcomProductGrid';
import { FilterMenuItem } from '../EcomProductGrid/ProductFilter';
import { SortBy } from '../../models/types';
import { useSiteWideContext } from '../../hooks/siteWideContext';
import { PackageComponentWrapper } from '../PackageComponentWrapper';

import './select.scss';

type SelectProps = {
  onSelect?:
    | ((a: DutchiePlus_PricingType) => void)
    | ((a: DutchiePlus_OrderType) => void)
    | ((a: SortBy) => void)
    | ((a: ProductViewType) => void);
  onSelectFilter?: EcomProductGridProps['onSelectFilter'];
  selected: string;
  id: string;
  label?: string;
  menuItems: MenuItemType[];
  placeholder?: string;
  disabled?: boolean;
  forMenuType?: boolean;
};

export type MenuItemType = {
  label: string;
  value: string;
};

export const Select = ({
  label,
  id,
  onSelect,
  onSelectFilter,
  selected,
  menuItems,
  placeholder,
  disabled = false,
  forMenuType = false
}: SelectProps) => {
  const [open, setOpen] = useState(false);
  const { width } = useSiteWideContext();

  if (!menuItems || !menuItems.length) return null;

  let displayLabel = '';
  if (!forMenuType) {
    const matchingLabel = menuItems.find((item) => item.value === selected);
    if (matchingLabel) displayLabel = matchingLabel.label;
  }
  return (
    <PackageComponentWrapper minWidth={false}>
      <div className="select-wrapper justify-content-between d-flex align-items-center margin-bottom-16 d-flex">
        {label ? (
          <label htmlFor={id} className="text-primary margin-right-10">
            {label}:
          </label>
        ) : null}
        <div className="select-dropdown-container">
          <Dropdown
            tabIndex={0}
            data-testid="dropdown"
            className="dropdown"
            panelClassName={`dropdown-panel-curaleaf`}
            placeholder={placeholder || label}
            name={label}
            value={selected}
            valueTemplate={
              forMenuType && width && width <= 576
                ? capitalize(selected.substring(0, 3))
                : forMenuType && width && width > 576
                  ? capitalize(selected)
                  : displayLabel
            }
            options={menuItems}
            scrollHeight={
              menuItems.length <= 4
                ? `${50 * menuItems.length + 25}px`
                : ' 225px'
            }
            onChange={(e) => {
              if (onSelect) onSelect(e.target.value as never);
              if (onSelectFilter)
                onSelectFilter('Sort', {} as FilterMenuItem, e.target.value);
            }}
            inputId={id}
            required={true}
            onShow={() => setOpen(true)}
            onHide={() => setOpen(false)}
            dropdownIcon={open ? 'pi pi-chevron-up' : 'pi pi-chevron-down'}
            disabled={disabled}
          />
        </div>
      </div>
    </PackageComponentWrapper>
  );
};
