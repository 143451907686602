'use client';

import { useEffect, useState } from 'react';

import { StorefrontCarousel as Default } from './default';
import { Loader } from './loader';
import { ProductTileProps } from '../ProductTile';
import { useSiteWideContext } from '../../hooks/siteWideContext';
import {
  productVariantsMap,
  productsAnalyticsMap
} from '../../utils/storefrontCarouselsMap';
import { DatalayerAnalytics } from 'services';
import { moodiDayScript } from '../../utils/moodiDayScript';

import './storefront-carousel.scss';

export type HomePageStorefrontCarouselProps = {
  banners: Omit<ProductTileProps, 'analyticsContext'>[];
  title: string;
  type: 'products';
};

export const HomePageStorefrontCarousel = (): JSX.Element | null => {
  const {
    addItemToCart,
    isMobile,
    selectedDispensary,
    selectedDispensaryID,
    selectedDispensaryLoading,
    userMenuType,
    websiteUrl
  } = useSiteWideContext();

  const [loading, setLoading] = useState<boolean>(selectedDispensaryLoading);
  const [productLists, setProductLists] =
    useState<HomePageStorefrontCarouselProps | null>(null);

  useEffect(() => {
    if (!selectedDispensaryLoading && selectedDispensaryID) {
      setLoading(true);
      fetch(
        `${websiteUrl}/api/product-listing/${selectedDispensaryID}?menuType=${userMenuType}`,
        { next: { revalidate: process.env.DISABLE_REDIS ? 1 : 300 } }
      )
        .then((res) => res.json())
        .then(({ data }) => {
          if (data && data[0].products) {
            setProductLists({
              ...data[0],
              banners: productsAnalyticsMap(
                addItemToCart,
                productVariantsMap(data[0].products, userMenuType),
                undefined
              )
            });
          }
        })
        .catch((error) => {
          DatalayerAnalytics.pushErrorEvent({
            category: 'api',
            location: 'packages/ui/components/StorefrontCarousel/homePage.tsx',
            description:
              (error as string) ||
              'Error fetching product-listing based on selected dispensary.',
            consolidateDispensary: selectedDispensary || undefined
          });
        })
        .finally(() => setLoading(false));
    } else {
      setLoading(false);
    }
  }, [selectedDispensaryID, selectedDispensaryLoading]);

  useEffect(() => {
    if (!loading) {
      moodiDayScript();
    }
  }, [loading]);

  if (loading) {
    return <Loader type={'products'} />;
  }
  if (productLists && !loading) {
    return (
      <Default
        title={productLists.title}
        type="products"
        banners={productLists.banners}
        isLoading={false}
        isMobile={isMobile}
      />
    );
  }
  return null;
};
