import Link from 'next/link';
import Image from 'next/image';
import { useState } from 'react';

import { dutchieFriendlyName, optionsMap } from 'services';
import { ProductListTileProps, ProductListTileOrderProps } from '.';
import { ecommerceSanitizeQuery } from '../../utils/url';
import { useSiteWideContext } from '../../hooks/siteWideContext';
import { STRAIN_TYPES_SIGNALS } from '../../utils/constants';

import { PackageComponentWrapper } from '../PackageComponentWrapper';
import { PriceSection } from '../PriceSection';
import { QuantitySelect } from '../QuantitySelect';
import { StrainTag } from '../ProductTile/StrainTag';

import ProductDefaultImage from '../../assets/images/product-placeholder.png';

export const ProductListTile = (props: ProductListTileProps) => {
  const { item, shopLink, type } = props;
  const { addItemToCart, isMobile, removeItemFromCart, width } =
    useSiteWideContext();
  const [loading, setLoading] = useState(false);

  const image = item.product.images[0]?.url || '';

  const updateQuantity = async (qty: 1 | -1) => {
    if (
      type === 'cart' &&
      props.variant &&
      addItemToCart &&
      props.setButtonLoading
    ) {
      setLoading(true);
      props.setButtonLoading(true);
      await addItemToCart(
        {
          product: item.product,
          quantity: qty,
          variant: props.variant,
          analyticsContext: {
            list: {
              id: 'cart_page',
              name: 'Cart Page'
            }
          },
          baseCartUrl: window.location.origin + shopLink
        },
        () => {
          setLoading(false);
          props.setButtonLoading(false);
        }
      );
    }
  };

  const priceElement = () => {
    if ((type !== 'cart' && type !== 'popup') || !props.variant) return null;
    return (
      <div className="price-info text-right">
        <PriceSection
          variant={props.variant}
          type="list-tile"
          isMobile={isMobile}
        />
      </div>
    );
  };

  const deleteItem = async () => {
    if (type === 'cart' && removeItemFromCart) {
      setLoading(true);
      props.setButtonLoading(true);
      await removeItemFromCart
        .mutateAsync({
          item,
          selectedVariant: props.variant
        })
        .finally(() => {
          setLoading(false);
          props.setButtonLoading(false);
        });
    }
  };

  const renderPriceSide = () => {
    switch (type) {
      case 'cart': {
        return (
          <div className="qpd-info d-flex align-items-center">
            <div className="qd d-flex flex-column align-items-center">
              <QuantitySelect
                value={item.quantity}
                max={(props.variant && (props.variant.quantity as number)) || 0}
                onDecrement={() => updateQuantity && updateQuantity(-1)}
                onIncrement={() => updateQuantity && updateQuantity(1)}
              />
              <a
                data-test="delete"
                className="delete body-xl font-regular pointer underline margin-top-5"
                onClick={() => deleteItem && deleteItem()}>
                Delete
              </a>
            </div>
            {width && width > 991 ? priceElement() : null}
          </div>
        );
      }
      case 'order': {
        return (
          <div className="pv-info d-flex justify-content-end align-items-center">
            <div className="quantity-tag body-m">{item.quantity}</div>
            <div
              data-has-variant={item.option && item.option !== 'N/A'}
              className="variant-button bg-white d-flex flex-column align-items-center justify-content-center">
              <span className="body-m">
                {(item.option !== 'N/A' && optionsMap(item.option)) || null}
              </span>
              <PriceSection
                variant={props.variant}
                type="pdp"
                isMobile={isMobile}
              />
            </div>
          </div>
        );
      }
      case 'popup':
      default: {
        return null;
      }
    }
  };

  const hasStrainTypeSignal = item.product.strainType
    ? STRAIN_TYPES_SIGNALS.includes(item.product.strainType)
    : false;

  return (
    <PackageComponentWrapper minWidth={false}>
      <div className={`product-list-tile d-flex bg-white padding-16 ${type}`}>
        {loading ? (
          <div className="spinner-icon d-flex align-items-center">
            <div className="loading-spinner" />
          </div>
        ) : (
          <>
            {type !== 'popup' ? (
              <StrainTag strain={item.product.strainType} />
            ) : null}
            <div
              data-order-history={
                (props as ProductListTileOrderProps).isOrderHistory || false
              }
              className="info-container d-flex align-items-center justify-content-between">
              <div className="inbv-info d-flex">
                <div className="image-container">
                  {type === 'popup' ? (
                    <StrainTag strain={item.product.strainType} />
                  ) : null}
                  <Link
                    className="body-xl text-decoration-none hover-underline"
                    href={ecommerceSanitizeQuery(
                      `${shopLink}/products/${item.product.id}`
                    )}>
                    <Image
                      data-test={image ? 'image' : 'default-image'}
                      src={image || ProductDefaultImage}
                      alt={image ? item.product.name : 'curaleaf-logo'}
                      fill={true}
                      sizes="100%"
                      style={{
                        objectFit: 'contain'
                      }}
                    />
                  </Link>
                </div>
                <div className="nbv d-flex flex-column justify-content-center">
                  {shopLink && item.product.id ? (
                    <Link
                      className="body-xl font-bold text-decoration-none hover-underline"
                      href={ecommerceSanitizeQuery(
                        `${shopLink}/products/${item.product.id}`
                      )}>
                      {item.product.name}
                    </Link>
                  ) : (
                    <a className="body-xl font-bold text-decoration-none text-primary">
                      {item.product.name}
                    </a>
                  )}
                  {item.product.brand ? (
                    <p
                      data-test="brand"
                      className="body-l text-black margin-bottom-0">
                      by {item.product.brand.name}
                    </p>
                  ) : null}
                  <div
                    className="moodi-day-rating-overlay"
                    data-productid={item.product.id}></div>
                  {props.variant && props.variant.option !== 'N/A' ? (
                    <h6 className="body-sm text-black margin-top-5 margin-bottom-0">
                      {optionsMap(props.variant.option)}
                    </h6>
                  ) : (
                    <h6 className="body-sm text-black margin-top-5 margin-bottom-0">
                      {item.product.cardDescription}
                    </h6>
                  )}
                  {(width && width <= 991) || type === 'popup'
                    ? priceElement()
                    : null}
                </div>
              </div>
              {renderPriceSide()}
            </div>
          </>
        )}
      </div>
    </PackageComponentWrapper>
  );
};
