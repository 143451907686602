'use client';

import { useState } from 'react';

import { ErrorBoundary } from '../../../utils/nullErrorBoundary';
import { StoresByStateType } from '../index';
import { Markdown } from '../../Markdown';
import { StoreCard } from '../../StoreCard';

import AccordionArrow from '../../../assets/svgs/arrows/icon-accordion-arrow.svg';

import './accordion-row.scss';

type AccordionRowProps = { storesPerState: StoresByStateType };

const AccordionRow = ({
  storesPerState
}: AccordionRowProps): JSX.Element | null => {
  const [toggleAccordion, setToggleAccordion] = useState(false);
  const hasMultipleStores = storesPerState.stores.length >= 4;
  const hasSplitHours =
    storesPerState.stores.some((store) => store.todaysHours?.includes(', ')) ??
    false;

  return (
    <ErrorBoundary component="accordion-row">
      <div className="accordion-row">
        <div
          tabIndex={0}
          className="accordion-row-upper d-flex justify-content-between align-items-center padding-bottom-16"
          onClick={() => setToggleAccordion(!toggleAccordion)}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              setToggleAccordion(!toggleAccordion);
            }
          }}
          data-test="accordion-upper">
          <h3 data-test="state-name" className="text-primary margin-0">
            {storesPerState.title}
          </h3>
          <div
            className="accordion-arrow"
            style={{
              transform: toggleAccordion ? 'rotate(180deg)' : 'rotate(0deg)'
            }}>
            <AccordionArrow data-test="arrow-icon" />
          </div>
        </div>
        {toggleAccordion && storesPerState.curaleaf_com?.copy?.length ? (
          <div className="coming-soon container-uw" data-test="coming-soon">
            <Markdown content={storesPerState.curaleaf_com.copy} />
          </div>
        ) : toggleAccordion ? (
          <div
            data-multiple-stores={hasMultipleStores}
            data-split-hours={hasSplitHours}
            className="store-list"
            data-test="store-dropdown">
            {storesPerState.stores.map((store, i) => (
              <StoreCard
                key={`${store.friendlyName}-${i}`}
                dispensary={store}
                type="location"
              />
            ))}
          </div>
        ) : null}
      </div>
    </ErrorBoundary>
  );
};

export default AccordionRow;
