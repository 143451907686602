import { LoadingSkeleton } from '../../../Loader/Skeleton';

export const Loader = (): JSX.Element | null => {
  const menuItems = new Array(3).fill(undefined);

  return (
    <div className="filter-accordion" data-test="filter-accordion">
      <div className="header d-flex justify-content-between" data-test="header">
        <h5
          className="body-l font-bold text-primary margin-0"
          data-test="heading">
          <LoadingSkeleton width="100px" />
        </h5>
        <a data-test="expand-btn" type="button">
          <LoadingSkeleton width="25px" />
        </a>
      </div>
      <div data-loading={true} className="filters d-flex flex-column">
        {menuItems.map((_, i) => {
          return (
            <div key={i} className="filter-item d-flex flex-row">
              <LoadingSkeleton width="75px" height="22px" />
            </div>
          );
        })}
      </div>
    </div>
  );
};
