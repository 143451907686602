import { useState } from 'react';
import classNames from 'classnames';

import './accordion.scss';

import Arrow from '../../assets/svgs/arrows/accordion-arrow-lg.svg';

export type AccordionProps = {
  additionalClass?: string;
  content: React.ReactNode;
  header: React.ReactNode;
  mode: 'light' | 'dark';
  padded?: boolean;
  defaultOpen?: boolean;
};

export const Accordion = ({
  additionalClass = '',
  content,
  header,
  mode,
  padded = true,
  defaultOpen = false
}: AccordionProps): JSX.Element | null => {
  const [open, setOpen] = useState<boolean>(defaultOpen);

  return (
    <>
      <div className={`curaleaf-accordion ${mode} ${additionalClass}`}>
        <div
          className={`accordion-header ${padded ? 'padded' : ''}`}
          data-test="question">
          <>
            {header}
            <button
              data-test="expand-btn"
              type="button"
              aria-expanded={open}
              className={classNames('expand-btn pointer', {
                up: open
              })}
              onClick={() => setOpen(!open)}>
              <Arrow />
            </button>
          </>
        </div>
        {content ? (
          <div
            className={open ? 'accordion-content active' : 'accordion-content'}>
            <div>{content}</div>
          </div>
        ) : null}
      </div>
    </>
  );
};
