import { useState } from 'react';
import Image from 'next/legacy/image'; // graph images missing dimensions so need to be on legacy

import { imgPath } from '../../../utils/imgPath';
import { imageLoader } from '../../../utils/imageLoader';

import { BrandDescriptionProps } from './index';
import { PackageComponentWrapper } from '../../PackageComponentWrapper';

import ChevronUp from '../../../assets/svgs/arrows/icon-brand-accordion-arrow.svg';

export const renderExpandButton = (
  open: boolean,
  setOpen: (bool: boolean) => void
) => {
  return (
    <button
      data-test="expand-btn"
      type="button"
      aria-expanded={open}
      className={`expand-btn pointer ${open ? 'open' : 'close'}`}
      onClick={() => setOpen(!open)}>
      <ChevronUp data-test="curaleaf-close-dt" />
    </button>
  );
};

export const BrandDescription = ({
  productBrand
}: BrandDescriptionProps): JSX.Element | null => {
  const [open, setOpen] = useState<boolean>(true);

  if (productBrand && productBrand.description) {
    return (
      <PackageComponentWrapper minWidth={false}>
        <div className="brand-description-accordion">
          <div className="brand-heading d-flex align-items-center justify-content-between">
            <h2 className="title-l text-black margin-bottom-0">
              About {productBrand.name}
            </h2>
            {renderExpandButton(open, setOpen)}
          </div>
          <div className={open ? 'content active' : 'content'}>
            <div className="brand-description" data-test="bd-withimage">
              {productBrand.imageUrl ? (
                <div className="image-col margin-bottom-16">
                  <Image
                    src={imgPath(productBrand.imageUrl)}
                    alt={productBrand.name}
                    layout="fill"
                    title={`${productBrand.name} logo`}
                    loader={imageLoader}
                    objectFit="contain"
                  />
                </div>
              ) : null}
              <div className="description-col">
                <div>
                  <p className="body-l text-black">
                    {productBrand.description}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PackageComponentWrapper>
    );
  }
  return null;
};
