import { PackageComponentWrapper } from '../PackageComponentWrapper';
import { Accordion } from '../Accordion';
import { Markdown } from '../Markdown';

import './faq-block.scss';

export type FAQBlockProps = {
  anchor?: string;
  content: string;
  faqs: { answer: string; question: string }[];
};

export const FAQBlock = ({
  anchor,
  content,
  faqs
}: FAQBlockProps): JSX.Element | null => {
  if (!faqs || !faqs.length) return null;

  return (
    <PackageComponentWrapper>
      <div id={anchor} className="faq-block-curaleaf" data-test="faq-block">
        <div className="heading-content m-container-sm">
          {content && content.length ? (
            <Markdown
              additionalClass="heading justify-content-space-center"
              data-test="text-block-content"
              content={content}
            />
          ) : null}
        </div>
        <div className="accordion-wrapper">
          {faqs.map((element) => (
            <Accordion
              key={element.question}
              mode="light"
              padded={true}
              header={
                <h6
                  className="question mh6 text-primary margin-bottom-0"
                  data-test="question">
                  {element.question}
                </h6>
              }
              content={
                <div className="answer container-lr">
                  <Markdown
                    data-test="text-block-content"
                    content={element.answer}
                  />
                </div>
              }
            />
          ))}
        </div>
      </div>
    </PackageComponentWrapper>
  );
};
