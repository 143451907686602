'use client';

import { useSiteWideContext } from '../../hooks/siteWideContext';
import { CTAButton } from '../CTAButton';
import { PackageComponentWrapper } from '../PackageComponentWrapper';

export type CTADividerProps = {
  ctabutton: {
    text: string;
    url: string;
  };
  loggedInText?: string;
  mode: string;
  text: string;
};

export const CTADivider = ({
  ctabutton,
  loggedInText,
  mode = 'dark',
  text
}: CTADividerProps): JSX.Element | null => {
  const { isLoggedIn, width } = useSiteWideContext();

  let background = 'primary';
  let header = 'white';

  if (mode === 'light') {
    background = 'white';
    header = 'primary';
  }

  let container = `bg-${background} d-flex justify-content-center align-items-center padding-45`;
  let title = `margin-right-40 text-${header}`;

  if (width && width < 768) {
    container = `bg-${background} d-flex flex-column text-center padding-16`;
    title = `text-${header} margin-bottom-16`;
  }

  return (
    <PackageComponentWrapper additionalClass={`bg-${background}`}>
      <div className={container}>
        <h2 className={`${title} margin-bottom-0`}>{text}</h2>
        <div>
          <CTAButton
            background_hex_color={`var(--${header})`}
            text={isLoggedIn && loggedInText ? loggedInText : ctabutton.text}
            url={ctabutton.url}
            text_hex_color={`var(--${background})`}
            variant={'tertiary'}
          />
        </div>
      </div>
    </PackageComponentWrapper>
  );
};
