import { EcomOrderConfirmationProps } from './index';
import { useSiteWideContext } from '../../hooks/siteWideContext';
import { useDispensaryPathContext } from '../../hooks/dispensaryPathContextProvider';
import { Markdown } from '../Markdown';
import { OrderSummary } from '../OrderSummary';
import { PackageComponentWrapper } from '../PackageComponentWrapper';
import { ProductListTile } from '../ProductListTile';
import { optionsMap } from 'services';

export const OrderConfirmation = ({
  order
}: EcomOrderConfirmationProps): JSX.Element | null => {
  const { userMenuType } = useSiteWideContext();
  const { currentPathDispensary } = useDispensaryPathContext();
  if (!currentPathDispensary || !order) return null;

  const documents =
    userMenuType === 'MEDICAL'
      ? 'drivers license and medical card'
      : 'drivers license';
  const confirmation = order.confirmationText;
  const content = confirmation
    .replace('[ORDERNUMBER]', order.orderNumber)
    .replace('[DISPENSARYNAME]', currentPathDispensary.friendlyName)
    .replace('[DOCUMENTS]', documents);

  const totalOrderItem = order.items.reduce(
    (total, item) => total + item.quantity,
    0
  );

  return (
    <PackageComponentWrapper>
      <div className="order-confirmation container-lr">
        <Markdown
          additionalClass="order-content text-center"
          content={content}
        />
        <div className="order-summary">
          <div className="header d-flex justify-content-between margin-bottom-10">
            <h6 className="mh6 text-primary">{totalOrderItem} Items</h6>
            {process.env.IS_KIOSK !== 'true' ? (
              <h6 className="body-xl text-primary">
                Pick up at {order.dispensaryName}
              </h6>
            ) : null}
          </div>
          <div className="order-tile-wrapper">
            {order.items.map((item) => {
              const variant = item.product.variants.find(
                (v) => optionsMap(v.option) === optionsMap(item.option)
              );
              if (!variant) return null;
              return (
                <ProductListTile
                  key={item.product.id}
                  props={{
                    item,
                    isLoading: false,
                    shopLink: currentPathDispensary.shopLink,
                    variant,
                    type: 'order'
                  }}
                />
              );
            })}
          </div>
          <div className="order-total-wrapper d-flex justify-content-end">
            <div className="order-total margin-bottom-16 text-black">
              <OrderSummary
                props={{
                  isLoading: false,
                  pricing: {
                    discounts: order.discounts,
                    subtotal: order.subtotal,
                    taxes: order.tax,
                    total: order.total,
                    fees: order.fees / 100
                  },
                  type: 'order'
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </PackageComponentWrapper>
  );
};
