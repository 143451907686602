'use client';

import { createContext, ReactNode, useContext, useEffect } from 'react';

import {
  Consolidate_Dispensary,
  CurrentPathDispensary,
  DutchiePlus_PricingType,
  getMinHeightClass,
  InformationBannerType
} from 'services';
import { AlertProps } from '../components/Alert';
import { FeedbackTab } from '../components/FeedbackTab';
import { useParams } from 'next/navigation';
import { useSiteWideContext } from './siteWideContext';
import { SpecialTileProps } from '../components/SpecialTile';
import { MailingOptInBanner } from '../components/MailingOptIn/Banner';
import { SpecialsTray } from '../components/SpecialsTray';

export interface DispensaryPathWrapperProps {
  alerts: (AlertProps & { id: number })[];
  currentPathDispensary: CurrentPathDispensary;
  defaultMenuType: DutchiePlus_PricingType;
  offerList: SpecialTileProps[];
  todaysOffers: InformationBannerType | null;
}

// Create Context Object
export const DispensaryPathContext = createContext<DispensaryPathWrapperProps>(
  {} as DispensaryPathWrapperProps
);

export type DispensaryPathContextProviderProps = {
  children: ReactNode;
  // Props that need to be passed in
  contextProps: Omit<DispensaryPathWrapperProps, 'defaultMenuType'>;
};

// Context for current shop path dispensary
export const DispensaryPathContextProvider = ({
  children,
  contextProps
}: DispensaryPathContextProviderProps) => {
  const {
    cart,
    isMobile,
    selectedDispensary,
    selectedDispensaryLoading,
    setSelectedDispensary,
    userMenuType
  } = useSiteWideContext();

  useEffect(() => {
    // update selected dispensary to current path dispensary
    if (
      (!selectedDispensaryLoading && !selectedDispensary) ||
      (selectedDispensary &&
        selectedDispensary.slug !== contextProps.currentPathDispensary.slug)
    ) {
      setSelectedDispensary(contextProps.currentPathDispensary.uid);
      cart.refetch();
    }
  }, [
    contextProps.currentPathDispensary.slug,
    selectedDispensary,
    selectedDispensaryLoading
  ]);

  /* ------ FEEDBACK ------ */
  const feedbackUrl =
    contextProps.currentPathDispensary.storefrontMap?.feedbackUrl || '';

  /* ------------------------------------------------------ */

  const context = {
    ...contextProps,
    // DEFAULTS MENUTYPE FROM USER MENUTYPE > RECREATIONAL > FIRST TYPE IN ARRAY. This way a user can still see products even if the dispensary doesn't have their menutype.
    defaultMenuType: contextProps.currentPathDispensary.menuTypes.includes(
      userMenuType
    )
      ? userMenuType
      : (contextProps.currentPathDispensary
          .menuTypes[0] as Consolidate_Dispensary['menuTypes'][number])
  };

  const params = useParams();
  const isKiosk = process.env.IS_KIOSK === 'true';

  const minHeightClass = getMinHeightClass(
    'ecom',
    isKiosk,
    process.env.CONTENTSTACK_ENVIRONMENT
  );

  return (
    <DispensaryPathContext.Provider value={context}>
      <div className={minHeightClass}>
        <FeedbackTab url={feedbackUrl} />
        {children}
      </div>
      <MailingOptInBanner />
      {params.productId || isKiosk ? null : (
        <SpecialsTray
          isMobile={isMobile}
          selectedDispensary={contextProps.currentPathDispensary}
          offerList={contextProps.offerList}
          todaysOffers={contextProps.todaysOffers}
        />
      )}
    </DispensaryPathContext.Provider>
  );
};

// Use this in child components
export const useDispensaryPathContext = () => useContext(DispensaryPathContext);
