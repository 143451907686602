import { useState } from 'react';
import { FilterAccordionProps } from './index';
import { SortBySelect } from '../../ProductSortFilter/default';
import { FilterCheckboxItem } from './FilterTypes/checkbox';
import { FilterSliderItem } from './FilterTypes/slider';
import { FilterRedirectItem } from './FilterTypes/redirect';
import { FilterMenuItem } from '..';

import ArrowIcon from '../../../../assets/svgs/arrows/chevron-down.svg';

export const FilterAccordion = ({
  filters,
  header,
  onSelect,
  pageType,
  shopLink,
  sortBy
}: FilterAccordionProps): JSX.Element | null => {
  const [open, setOpen] = useState<boolean>(true);
  if (header !== 'Sort' && !filters[header].length) return null;
  const redirectItem =
    (pageType === 'AllBrandsPage' && header === 'Brands') ||
    header === 'Specials';
  const count =
    header !== 'Sort' && Array.isArray(filters[header])
      ? filters[header].filter((f) => f.isSelected)
      : [];

  return (
    <div className="filter-accordion" data-test="filter-accordion">
      <div
        className="header d-flex justify-content-between"
        data-menu-open={open}
        data-test="header">
        <h5
          className="body-l font-bold text-primary margin-0"
          data-test="heading">
          {header} {count.length > 0 ? `(${count.length})` : null}
        </h5>
        <a
          data-test="expand-btn"
          aria-expanded={open}
          type="button"
          className="expand-btn"
          onClick={() => setOpen(!open)}
          onKeyDown={(event) => {
            if (event.key === 'Enter') setOpen(!open);
          }}>
          <ArrowIcon data-accordion-open={open} />
        </a>
      </div>
      <div
        data-redirect={redirectItem}
        data-visible={open}
        className="filters d-flex flex-column">
        {filters[header]?.length ? (
          filters[header]?.map((item: FilterMenuItem) => {
            if (redirectItem) {
              return (
                <FilterRedirectItem
                  item={item}
                  key={item.id || item.label}
                  header={header}
                  pageType={pageType}
                  shopLink={shopLink}
                />
              );
            } else if (header === 'Potency') {
              return (
                <FilterSliderItem
                  header={header}
                  item={item}
                  key={item.label}
                  onSelect={onSelect}
                />
              );
            } else if (header !== 'Sort') {
              return (
                <FilterCheckboxItem
                  header={header}
                  item={item}
                  key={item.label}
                  onSelect={onSelect}
                />
              );
            } else {
              return null;
            }
          })
        ) : header === 'Sort' && filters ? (
          <SortBySelect
            filters={filters}
            onSelectFilter={onSelect}
            sortBy={sortBy}
          />
        ) : null}
      </div>
    </div>
  );
};
