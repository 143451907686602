import { useState } from 'react';
import { Dialog } from 'primereact/dialog';

import { FooterNavLinks } from '.';
import { CTAButton } from '../CTAButton';
import { PackageComponentWrapper } from '../PackageComponentWrapper';
import { Markdown } from '../Markdown';
import { Accordion } from '../Accordion';

export type FooterAccordionProps = {
  forKiosk: boolean;
  link: FooterNavLinks;
};

export const FooterAccordion = ({
  forKiosk,
  link
}: FooterAccordionProps): JSX.Element | null => {
  const [showLegalDialog, setShow] = useState(false);
  const [legalContent, setLegalContent] = useState('');

  return (
    <>
      {forKiosk && showLegalDialog ? (
        <Dialog
          className="legal-modal disable-scroll"
          draggable={false}
          style={{ width: '80vw' }}
          footer={
            <CTAButton
              text="Close"
              variant="secondary"
              callBackFunc={() => setShow(false)}
            />
          }
          onHide={() => setShow(false)}
          resizable={false}
          visible={showLegalDialog}>
          <PackageComponentWrapper>
            <div className="legal-content">
              <Markdown content={legalContent} />
            </div>
          </PackageComponentWrapper>
        </Dialog>
      ) : null}
      {link.links.length ? (
        <Accordion
          mode="dark"
          header={
            link.url ? (
              <a className="title-l text-white" href={link.url}>
                {link.heading}
              </a>
            ) : (
              <span className="title-l text-white">{link.heading}</span>
            )
          }
          content={
            <ul className="footer-link-list">
              {link.links.map((footerLink) => {
                if (footerLink.url) {
                  return (
                    <li key={footerLink.id}>
                      <a
                        key={footerLink.id}
                        href={footerLink.url}
                        className="text-white underline">
                        {footerLink.linkName}
                      </a>
                    </li>
                  );
                } else if (footerLink.content) {
                  return (
                    <li key={footerLink.id}>
                      <a
                        tabIndex={0}
                        role="button"
                        className="text-white pointer body-l underline"
                        key={link.id}
                        onClick={() => {
                          // typescript forcing double content check
                          if (footerLink.content) {
                            setLegalContent(footerLink.content);
                            setShow(true);
                          }
                        }}
                        data-test="legal-link">
                        {footerLink.linkName}
                      </a>
                    </li>
                  );
                }
                return null;
              })}
            </ul>
          }
        />
      ) : null}
    </>
  );
};
